import React from 'react'
import { Layout, Col, Row, PageHeader, Typography, Spin, DatePicker } from "antd";
import axios from "axios";
//import '../../Styles/Modules/Dashboard/dashboard.css';

import { CardSaldosGraph, CardMontoVentaMensual, CardMontoVentaAnual, CardDashboardGraph } from '../Widgets/Cards';
import moment from 'moment';

const { Content } = Layout;
const { Title } = Typography;

export default class Dashboard extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			ventas: 0,
			clientes: 0,
			boletos: 0,
			ultimas_ventas: [],
			ultimos_clientes: [],
			loading: false,

			selectedDate: moment()
		}
	}

	handleDateChange = (date, dateString) => {
		this.setState({ selectedDate: dateString }, () => {
			this.getInfo();
		});
	};

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getInfo()
	}

	/**
	*
	* @memberof Ventas
	* @method getInfo
	* @description Obtiene la informacion de las ventas
	*/
	getInfo = () => {

		const { selectedDate } = this.state;
		const params = selectedDate ? { fecha: selectedDate } : {}; // Incluye la fecha si está seleccionada
	
		this.setState({ loading: true })
		axios.get('/dashboard', {
			params
		})
			.then(response => {
				this.setState({
					...response.data
				})

			}).catch(error => {

			}).finally(() => {
				this.setState({ loading: false })
			})

	}

	render() {
		return (
			<>
				<PageHeader
					className="custom-page-header"
					title={<Row>
						<Col style={{ flex: 1 }}>
							<Title level={3}>Dashboard</Title>
						</Col>
						{/* <Col>
							<DatePicker
								picker="month"
								onChange={this.handleDateChange}
								placeholder="Seleccionar mes y año"
							/>
						</Col> */}
					</Row>}
				/>
				<Content className="admin-content content-bg pd-1">
					<Spin spinning={this.state.loading}>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<Row gutter={[16, 16]} className='mb-1'>

									<Col lg={8} md={24} sm={24} xs={24}>
										<CardSaldosGraph
											title={"Ventas Realizadas"}
											info={`${this.state.ventas?.toMoney ? this.state.ventas?.toMoney(true, false) : 0}`}
										/>
									</Col>
									<Col lg={8} md={24} sm={24} xs={24}>
										<CardSaldosGraph
											title={"Clientes Registrados"}
											info={`${this.state.clientes?.toMoney ? this.state.clientes?.toMoney(true, false) : 0}`}
										/>
									</Col>
									<Col lg={8} md={24} sm={24} xs={24}>
										<CardSaldosGraph
											title={"Boletos vendidos"}
											info={`${this.state.boletos?.toMoney ? this.state.boletos?.toMoney(true, false) : 0}`}
										/>
									</Col>
								</Row>
								<Row justify='center' className="pt-1" gutter={[16, 16]}>
									<Col xs={24} md={24}>
										<Title level={4}> Ultimas Ventas</Title>
										<CardDashboardGraph
											emptyText="Sin ventas"
											data={this.state.ultimas_ventas}
										/>
									</Col>
									{/* <Col xs={24} md={12}>
										<Title level={4}> Ultimos Clientes</Title>
										<CardDashboardGraph
											emptyText="Sin clientes"
											data={this.state.ultimos_clientes}
											clientes={true}
										/>
									</Col> */}
								</Row>
							</Col>
						</Row>
					</Spin>
				</Content>
			</>
		)
	}
} 