import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";

import '../Styles/Global/admin.css';

// Routers
import RouterCompras from "./Customer/RouterCompras";
import RouterCuenta from "./Customer/RouterCuenta";


// Components
import Navbar from "../Components/Header/HeaderCustomer";
import SidebarCustomer from "../Components/Sidebar/SidebarCustomer"
import Footer from "../Components/Public/Footer"


const { Content } = Layout;

class AdminRoutes extends Component {
	render() {
		return (
			<Layout className="layout" style={{ overflowX: "hidden" }}>
				<Navbar />
				<Layout style={{width: "100%", maxWidth: "1500px", margin: "0 auto"}}>
					<SidebarCustomer />
					<Content className="admin-content">
						<Routes>
                            <Route path="" element={<RouterCompras />} />
                            <Route path="compras/*" element={<RouterCompras />} />
                            <Route path="cuenta/*" element={<RouterCuenta />} />
						</Routes>
					</Content>
				</Layout>
				<Footer/>
			</Layout>
		)
	}
}

export default (props) => <AdminRoutes {...props} />