import React from "react";
import { Row, Col, Button, Image, Layout, Typography, Card } from "antd";
import { Link } from "react-router-dom";
import { FaSquareFull } from "react-icons/fa";
import axios from "axios";
// import Paragraph from "antd/lib/skeleton/Paragraph";
// import Title from "antd/lib/skeleton/Title";

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

export default class Recientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sorteos: [],
        };
    }

    componentDidMount() {
        axios
            .get("/recientes", {
                params: {
                    tipo: 1
                }
            })
            .then(({ data }) => {
                this.setState({ sorteos: data });
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    render() {
        const { sorteos } = this.state;
        return (
            <Content>
                <Row gutter={[25, 25]}>
                    {sorteos.map((sorteo, index) => <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Card
                            data-aos="fade-up"
                            data-aos-delay={String(index * 3 + 1) + '00'}//"300"
                            cover={<img src={sorteo.banner ? `${axios.defaults.baseURL}/info/${sorteo._id}/banner` : "/img/recientes-1.png"} width={"100%"} style={{ objectFit: "cover" }} height={300} />}
                        >
                            <Title level={4}>
                                {sorteo.nombre}
                            </Title>
                            <Paragraph >
                                {sorteo.descripcion || " Da click para conocer el sorteo"}
                            </Paragraph>
                            <Row style={{ justifyContent: "end" }}>
                                <a href={`/evento/${sorteo.url}`} >
                                    <Button type="primary" className='boton-evento' disabled={sorteo.disponibles <= 0}>
                                        ¡Participar ahora desde ${sorteo.costo_boleto.toMoney()}!
                                    </Button>
                                </a>
                            </Row>
                        </Card>
                    </Col>
                    )}
                </Row>
            </Content>

        );
    }
}