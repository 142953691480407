import React, { Component } from 'react';

import { Layout, Row, Col, Menu, Typography, Button, Divider, Card, Input } from 'antd';

import { MailIcon, TelefonoIcon, UbicacionIcon } from '../Widgets/Iconos';
import { FaSquareFull } from 'react-icons/fa'
import { BsInstagram, BsFacebook, BsTiktok } from 'react-icons/bs'
import { AiOutlineTwitter } from 'react-icons/ai'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import WidgetFacebook from './WidgetFacebook';
import '../../Styles/Public/footer.scss'

const { Header, Footer, Sider, Content } = Layout;
const { Text, Title } = Typography;

class FooterPublic extends Component {
    render() {
        return (
            <>
                <Footer id='contacto' className='gradient-footer footer'>
                    <Row gutter={[0, 16]} align={"middle"} justify={"space-between"}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={8} xxl={4} className=' mr-1'>
                            <img className="w-100" src="/img/logo.svg" alt="José Hermosillo" style={{ maxWidth: '400px' }} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={17} xl={15} xxl={15} className="">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align={"middle"}>
                                        <Col span={24} className='flex-left'>
                                            <TelefonoIcon />
                                            <a className="flex-column-left pl-1" href='https://api.whatsapp.com/send?phone=5213471017645&text=%C2%A1Hola!%20Quiero%20contactar%20a%20FugapaDurango'>
                                                <Text className='info-titulo'>FUGA PA DURANGO</Text>
                                                <Text className='info-datos'> +52 1 347 101 7645 </Text>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align={"middle"}>
                                        <Col span={24} className='flex-left'>
                                            <MailIcon />
                                            <a className="flex-column-left pl-1" href='mailto:fugapadurango2023@gmail.com'>
                                                <Text className='info-titulo'> Correo Electrónico </Text>
                                                <Text className='info-datos'>fugapadurango2023@gmail.com</Text>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align={"middle"}>
                                        <Col span={24} className='flex-left'>
                                            <UbicacionIcon />
                                            <a className="flex-column-left pl-1" href='https://goo.gl/maps/F8tRWazQwmpWDY858?coh=178571&entry=tt' target='_blank'>
                                                <Text className='info-titulo'>Ubicación</Text>
                                                <Text className='info-datos'>6052 L st Omaha Nebraska 68117</Text>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider style={{ backgroundColor: "#6C6D72" }} />
                    <Row align={"middle"}>
                        <Col xs={24} sm={24} md={24} lg={14} xl={12} xxl={14}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    <Row className="elementos-footer">
                                        <ul>
                                            <li>
                                                <HashLink smooth to={'/#inicio'}>
                                                    INICIO
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#nosotros'}>
                                                    NOSOTROS
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#ultimos-sorteos'}>
                                                    SORTEOS
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#ganadores'}>
                                                    GANADORES
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#contacto'}>
                                                    CONTACTO
                                                </HashLink>
                                            </li>
                                        </ul>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={12} xxl={10}>
                            <WidgetFacebook style={{ width: "100%" }} />
                        </Col>
                    </Row>
                    <Divider style={{ backgroundColor: "#6C6D72" }} />
                    <Row justify="center">
                        <Title style={{ color: "white", textAlign: "center"}}>Metodos de Pago</Title>
                    </Row>
                    <Row style={{ width: "100%" }} gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8} lg={8} style={{ textAlign: "center", }}>
                            <Title level={5} style={{ textAlign: "center", color: "white", marginBottom: 12 }}>FUGA PA' DURANGO LLC</Title>
                            <img src="/img/zelle-fuga-pa-durango.svg" alt="Zelle" style={{ maxWidth: 150, display: 'block', margin: 'auto', marginBottom: 12 }} />
                            <Title level={5} copyable={{ text: '531-232-5998' }} style={{ textAlign: "center", color: "white" }}><i>Tel: 531-232-5998</i></Title>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                            <Title level={5} style={{ textAlign: "center", color: "white", marginBottom: 12 }}>BANCO: BBVA</Title>
                            <img src="/img/bbva.svg" alt="BBVA"
                                style={{
                                    maxWidth: 150,
                                    display: 'block',
                                    margin: 'auto auto auto',
                                    textAlign: "center",
                                    color: "white",
                                    // marginBottom: 12,
                                    // flex: 1
                                }}
                            />
                            <Text
                                copyable={{ text: '4152 3141 4002 1174' }}
                                style={{
                                    color: "white",
                                    marginBottom: 12,
                                }}
                            >CUENTA: 4152 3141 4002 1174</Text>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                            <Title level={5} style={{ textAlign: "center", color: "white" }}>BANCO: SPIN BY OXXO</Title>
                            <img src="/img/spin-oxxo-logo.svg" alt="SPIN" style={{
                                maxWidth: 150,
                                display: 'block',
                                margin: 'auto',
                                textAlign: "center",
                                color: "white",
                                // flex: 1
                            }} />
                            <Text style={{ textAlign: "center", color: "white" }} copyable={{ text: '4217 4700 2938 6212' }}>CUENTA: 4217 4700 2938 6212</Text>
                        </Col>
                    </Row>
                    <Divider style={{ backgroundColor: "#6C6D72" }} />
                    <Row align={"middle"} justify={"space-between"}>
                        <Col >
                            <Text className='copyright'>Copyright © ThrowCode Developement and Design</Text>
                        </Col>
                        <Col>
                            <Text className='redes'>¡Síguenos en nuestras redes sociales!
                                <a href='https://www.instagram.com/sorteosfugapadurango/' target='_blank'><BsInstagram className='ml-1' /></a>
                                <a href='https://www.facebook.com/sorteosfugapadurango/' target='_blank'><BsFacebook className='ml-1' /></a>
                                <a href='https://www.tiktok.com/@fugapadgo/' target='_blank'><BsTiktok className='ml-1' /></a>
                            </Text>
                        </Col>
                    </Row>

                </Footer>
            </>
        );
    }
}

export default function (props) {

    return <FooterPublic />

}