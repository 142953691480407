import React, { useEffect, useState } from 'react';
import { Select, message, Spin } from 'antd';
import axios from 'axios';

const { Option } = Select;

/**
 * @component SelectSorteo
 * @description Componente Select para elegir sorteos.
 */
export default function SelectSorteo({ value, onChange, ...props }) {
    const [sorteos, setSorteos] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchSorteos();
    }, []);

    const fetchSorteos = (search = '', page = 1, limit = 10) => {
        setLoading(true);
        axios
            .get('/landing/sorteos', {
                params: {
                    search,
                    page,
                    limit
                }
            })
            .then(({ data }) => setSorteos(data.data))
            .catch((error) => {
                message.error(error?.response?.data?.message ?? 'Error al obtener sorteos');
            })
            .finally(() => setLoading(false));
    };

    return (
        <Select
            showSearch
            allowClear
            placeholder="Seleccione un sorteo"
            value={value}
            onChange={onChange}
            onSearch={(search) => fetchSorteos(search)}
            filterOption={false}
            loading={loading}
            
            {...props}
        >
            {sorteos.map((sorteo) => (
                <Option key={sorteo._id} value={sorteo._id} item={sorteo}>
                    {sorteo.nombre}
                </Option>
            ))}
        </Select>
    );
}