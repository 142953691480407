import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Space, Button, message, Carousel } from "antd";
import axios from 'axios';
import moment from 'moment'
import { AiOutlineEye } from 'react-icons/ai';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

//Componentes
import { EstatusVenta } from "../Utils";
import { IconEdit } from '../Widgets/Iconos';

//Modal
import ModalVentasDetalle from "./Modales/ModalDetalle"
import { Link } from 'react-router-dom';


const { Content } = Layout;
const { Title, Text } = Typography;

export default class Dashboard extends React.Component {

	/**
	 *Creates an instance of Usuarios.
	 * @param {*} props
	 * @memberof Usuarios
	 */
	constructor(props) {
		super(props)
		this.state = {

			loading: false,
			ventas: {
				data: [],
				limit: 10,
				page: 1,

				total: 0,
				pages: 0,
				filters: [],

			},
			sorteos: [],
			venta_id: null
		}
	}

	carouselRef = React.createRef();

	componentDidMount() {
		this.getVentas()
		this.getSorteos()
	}

	/**
	*
	* @memberof Ventas
	* @method getVentas
	* @description Obtiene la informacion de las ventas
	*/
	getVentas = async ({
		page = this.state.ventas.page,
		limit = this.state.ventas.limit,
	} = this.state.ventas) => {

		this.setState({ loading: true })

		axios.get('/customer/ventas', {
			params: { limit, page }
		})
			.then(({ data }) => {
				this.setState({ ventas: data })
			})
			.catch(res => {
				message.error(res.response.data.message)
			})
			.finally(() => this.setState({ loading: false }))


	}

	getSorteos = () => axios
		.get("/recientes", {
			params: {
				tipo: 1
			}
		})
		.then(({ data }) => {
			console.log("data", data)
			this.setState({ sorteos: data });
		})
		.catch((error) => {
			console.log("error", error);
		});


	renderSorteo = sorteo => <Card style={{ margin: "1em" }} hoverable cover={<img src={sorteo.banner ? `${axios.defaults.baseURL}/info/${sorteo._id}/banner` : "/img/recientes-1.png"} width={"100%"} style={{ objectFit: "cover" }} height={200} alt="Banner" />}>
		<Row className='width-100' >
			<Text className='evento-titulo' style={{ fontSize: 20 }}>
				{sorteo.nombre}
			</Text>
		</Row>
		<Row className='width-100'>
			<Text className='evento-descripcion' style={{ fontSize: 16 }}>
				{sorteo.descripcion}
			</Text>
		</Row>
		<Row className='mt-2 width-100' justify={"end"}>
			<Link to={`/evento/${sorteo.url}`} reloadDocument={true}>
				<Button className='boton-evento'>
					¡Participar ahora!
				</Button>
			</Link>

		</Row>
	</Card>


	render() {
		return (
			<>
				<Content className="p-1">
					<Row gutter={[1, 16]} className="pt-1" style={{ width: "100%" }} align={"middle"}>
						<Col span={24} className="customer-header-page">
							<Title level={3}>¡Revisa nuestros ultimos sorteos!</Title>
						</Col>
						<Col span={this.state.sorteos.length > 3 ? 1 : 0} className='center'>
						{this.state.sorteos.length > 3 ? <Button onClick={() => this.carouselRef.prev()}>
								<LeftOutlined />
							</Button> : null}
							

						</Col>
						<Col span={this.state.sorteos.length > 3 ? 22 : 24} className="customer-body-page">


							{this.state.sorteos.length > 2 ? <Carousel
								ref={node => (this.carouselRef = node)}
								slidesToShow={3}
								slidesToScroll={3}
								infinite={true}
								dots={true}
								autoplay={true}
								responsive={[
									{
										breakpoint: 1200,
										settings: {
											slidesToShow: 3,
											slidesToScroll: 3,
											infinite: true,
											dots: true
										}
									},
									{
										breakpoint: 1180,
										settings: {
											slidesToShow: 2,
											slidesToScroll: 2,
											initialSlide: 2,
											infinite: true,
											dots: true
										}
									},
									{
										breakpoint: 991,
										settings: {
											slidesToShow: 3,
											slidesToScroll: 3,
											initialSlide: 2,
											infinite: true,
											dots: true
										}
									},
									{
										breakpoint: 912,
										settings: {
											slidesToShow: 2,
											slidesToScroll: 2,
											initialSlide: 2,
											infinite: true,
											dots: true
										}
									},
									
									{
										breakpoint: 768,
										settings: {
											slidesToShow: 1,
											slidesToScroll: 1,
											infinite: true,
											dots: true
										}
									}
								]}
							>
								{
									this.state.sorteos.map(sorteo => <div style={{ padding: "0.5em" }}>
										{this.renderSorteo(sorteo)}
									</div>)
								}
							</Carousel> : <Row style={{ width: "100%" }}>
								{
									this.state.sorteos.map(sorteo => <Col lg={8}>
										{this.renderSorteo(sorteo)}
									</Col>)
								}
							</Row>}


						</Col>
						<Col span={this.state.sorteos.length > 3 ? 1 : 0} className='center'>
							{this.state.sorteos.length > 3 ? <Button onClick={() => this.carouselRef.next()}>
								<RightOutlined />
							</Button> : null}
						</Col>
					</Row>
					<Row gutter={[1, 16]} className="pt-1">
						<Col span={24} className="customer-header-page">
							<Title level={3}>Compras Realizadas</Title>
						</Col>
						<Col span={24} className="customer-body-page">
							<List
								loading={this.state.loading}
								className="component-list"
								itemLayout="horizontal"
								locale={{ emptyText: "Sin Compras" }}
								dataSource={this.state.ventas.data}
								pagination={{
									current: this.state.ventas.page,
									pageSize: this.state.ventas.limit,
									total: this.state.ventas.total,
									hideOnSinglePage: false,
									position: 'bottom',
									onChange: (page, limit) => this.getVentas({ page, limit })

								}}

								renderItem={item => (
									<List.Item className="component-list-item">

										<Card className="card-list">
											<Row className="width-100 ">

												<Col span={5} className="center">
													<Text strong>{item?.sorteo_id?.nombre ?? "-"}</Text>
												</Col>
												<Col span={4} className="center">
													<Text>{item.cantidad} Boleto(s)</Text>
												</Col>
												<Col span={5} className="center">
													<Text>$ {item.monto?.toMoney(true)} USD</Text>
												</Col>
												<Col span={5} className="center">
													<Text>{moment(item.fecha).format("DD-MM-YYYY HH:mm")}</Text>
												</Col>
												<Col span={3} className="center">
													{EstatusVenta(item.estatus, item)}
												</Col>
												<Col span={2} className="center">
													<Space>
														<Button
															type="primary"
															icon={<AiOutlineEye />}
															onClick={() => this.setState({ modalVentasDetalle: true, venta_id: item._id })} />
													</Space>
												</Col>
											</Row>
										</Card>
									</List.Item>
								)}
							/>
						</Col>
					</Row>
				</Content>
				<ModalVentasDetalle
					visible={this.state.modalVentasDetalle}
					onClose={() => {
						this.setState({ modalVentasDetalle: false })
						this.getVentas()
					}}
					venta_id={this.state.venta_id}

				/>
			</>
		)
	}
} 