import { useEffect } from 'react';

function FacebookPage(props) {
  useEffect(() => {
    // Check if the SDK script is already loaded
    if (!document.getElementById('facebook-jssdk')) {
      // Create a script element
      const script = document.createElement('script');
      script.id = 'facebook-jssdk';
      script.src = 'https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v19.0';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);

      script.onload = () => {
        if (window.FB) {
          window.FB.XFBML.parse();
        }
      };
    }
  }, []);

  return (
    <div id="fb-root" {...props}>
      <div className="fb-page"
        data-href="https://www.facebook.com/sorteosfugapadurango"
        data-tabs="timeline"
        data-width="500"
        data-height="350"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true">
        <blockquote cite="https://www.facebook.com/sorteosfugapadurango" className="fb-xfbml-parse-ignore">
          <a href="https://www.facebook.com/sorteosfugapadurango">Fuga Pa’ Durango</a>
        </blockquote>
      </div>
    </div>
  );
}

export default FacebookPage;
