import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom";
import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
//componentes
import ThemeEdit from '../Components/ThemeEdit/index';
import Landing from '../Components/Public/Landing';
import Info from '../Components/Public/EventoDetalles';
import Login from '../Components/Auth/Login';
import Recovery from '../Components/Auth/Recovery';
import UpdatePassword from '../Components/Auth/UpdatePassword';
import Register from '../Components/Auth/Register';

import ModalComprar from '../Components/Public/ModalComprar';
import { SetCarrito, Carrito } from '../Hooks/Carrito';


import { OpenModalCompra, SetOpenModalCompra } from '../Hooks/OpenModalCompra';
import { Compra, SetCompra } from '../Hooks/SorteoCompra';

import BusquedaBoletos from '../Components/Public/BusquedaBoletos';


import { Row, Col, Avatar } from 'antd';

/**
 * @function PublicRoutes
 * @description Router para rutas publicas
 * @export
 */
function PublicRoutes({ setUser }) {

	let [carrito, setCarrito] = React.useState([]);
	let [sorteoCompra, setSorteoCompra] = React.useState([]);
	let [openModalCompra, setOpenModalCompra] = React.useState(false);

	const navigate = useNavigate();

	console.log("@sorteoCompra", sorteoCompra)

	return (
		// Compra, SetCompra
		<SetCompra.Provider value={setSorteoCompra}>
			<Compra.Provider value={sorteoCompra}>
				<SetOpenModalCompra.Provider value={setOpenModalCompra}>
					<OpenModalCompra.Provider value={openModalCompra}>
						<Carrito.Provider value={carrito}>
							<SetCarrito.Provider value={setCarrito}>
								<Layout style={{ overflowX: "hidden" }}>
									<Routes>
										<Route path="" element={<Landing />} />
										<Route path="evento/:unique_url" element={<Info />} />
										<Route path='login' element={<Login />} />
										{/* <Route path='login' element={<Login />} /> */}
										<Route path='password/recovery' element={<Recovery />} />
										<Route path='recovery/:token' element={<UpdatePassword />} />
										<Route path='register/:token' element={<UpdatePassword />} />
										<Route path='register' element={<Register />} />
										<Route path='boletos' element={<BusquedaBoletos />} />
										<Route
											path="theme"
											element={<ThemeEdit />}
										/>
									</Routes>
								</Layout>
								<ModalComprar
									visible={openModalCompra}
									onClose={() => setOpenModalCompra(false)}
								/>
							</SetCarrito.Provider>
						</Carrito.Provider>
					</OpenModalCompra.Provider>
				</SetOpenModalCompra.Provider>
			</Compra.Provider>
		</SetCompra.Provider>



	)
}

export default PublicRoutes;