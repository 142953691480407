import React, { useEffect, useState } from 'react';
import { Select, message, Spin } from 'antd';
import axios from 'axios';

const { Option } = Select;

/**
 * @component SelectSorteo
 * @description Componente Select para elegir sorteos.
 */
export default function SelectBoletos({ sorteoId, value, digits = 5, onChange, setBoleto, ...props }) {
    const [boletos, setBoletos] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (sorteoId) {
            fetchBoletos();
        }
    }, [sorteoId]);

    const fetchBoletos = (search = '', page = 1, limit = 10) => {
        setLoading(true);

        if (digits && search.length == digits && setBoleto) {
            setBoleto(search)
        }
        axios
            .get('/landing/boletos', { params: { sorteo_id: sorteoId, search, page, limit } })
            .then(({ data }) => setBoletos(data.data))
            .catch((error) => {
                message.error(error?.response?.data?.message ?? 'Error al obtener boletos');
            })
            .finally(() => setLoading(false));
    };

    return (
        <Select
            mode="multiple"
            showSearch
            allowClear
            placeholder="Seleccione un boleto"
            value={value}
            onChange={onChange}
            onSearch={(search) => fetchBoletos(search)}
            filterOption={false}
            loading={loading}
            {...props}
        >
            {boletos.map((boleto) => (
                <Option key={boleto.numero} value={boleto.numero}>
                    {String(boleto.numero).padStart(digits, '0')}
                </Option>
            ))}
        </Select>
    );
}
