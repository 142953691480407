import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';



const EstatusRifa = (estatus) => {

	let tags = {
		0: <Tag color="#ff4d4f">Cancelada</Tag>,
		1: <Tag color="#FF5500">Cerrada</Tag>,
		2: <Tag color="#D9C300">En Venta</Tag>,
		3: <Tag color="#108EE9">Vendida</Tag>,
		4: <Tag color="#87D068">Concluida</Tag>,
	}

	return tags[estatus] ?? "N/A"

}


const EstatusCliente = (estatus) => {

	let tags = {
		0: <Tag color="#ff4d4f">Perdido</Tag>,
		1: <Tag color="#FF5500">Registrado</Tag>,
		2: <Tag color="#D9C300">Contactado</Tag>,
		3: <Tag color="#108EE9">Ha Comprado</Tag>,
		4: <Tag color="#87D068">Pagado</Tag>,
	}

	return tags[estatus] ?? "N/A"

}


const EstatusVenta = (estatus, item) => {

	let tags = {
		2: <Tag color="red">Cancelado</Tag>,
		6: <Tag color="red">Vencido</Tag>,
		1: <Tag color="yellow">Pendiente</Tag>,
		4: <Tag color="orange">Por Confirmar</Tag>,
		3: <Tag color="green">Completado</Tag>,
		5: <Tag color="blue">Regalado</Tag>
	}


	if (moment(item.fecha).isAfter(moment.now())) {
		return  <Tag color="red">VENCIDA</Tag>		
		
	}

	return tags[estatus] ?? <Tag color="yellow">Venta no completada</Tag>

}




export {
	EstatusRifa,
	EstatusCliente,
	EstatusVenta
}