import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

/**
 * @const SelectPais
 * @description Select para los países registrados en el sistema
 */
const SelectPais = ({
    params = {},
    onClear = () => {},
    onSelect = () => {},
    tipo,
    ...props
}) => {
    const [paises, setPaises] = useState({
        data: [],
        page: 1,
        limit: 200,
        total: 0,
        search: null,
    });

    /**
     * @const getPaises
     * @description Obtiene los países
     */
    const getPaises = ({ page, limit = paises.limit, search } = paises) => {
        axios
            .get('/paises', {
                params: {
                    page,
                    limit,
                    search,
                    ...params,
                    tipo,
                },
                headers: { Authorization: sessionStorage.getItem('token') },
            })
            .then(({ data }) => {
                setPaises(data);
            })
            .catch((error) => {
                console.log('ERR, error', error);
                message.error(error?.response?.data?.message ?? 'Error al obtener los países');
            });
    };

    // componentDidMount
    useEffect(() => {
        getPaises();
    }, []);

    return (
        <Select
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search) => getPaises({ search })}
            onClear={() => {
                onClear();
                props.onChange?.(undefined);
                onSelect(undefined);
            }}
            onSelect={(usuario) => {
                if (!props.mode) {
                    props.onChange?.(usuario.value);
                    onSelect(usuario.value);
                }
            }}
            onChange={(values) => {
                if (props.mode === 'multiple') {
                    const arr = values.map((e) => e.value);
                    props.onChange?.(arr);
                    onSelect(arr);
                }
            }}
            // getPopupContainer={}
            getPopupContainer={(trigger) => document.querySelector(".ant-modal-wrap")}
            {...props}
        >
            {paises?.data?.map(({ _id, nombre, phonecode }) => {

                if (tipo == "phone") {
                    return (
                        <Option value={phonecode} key={phonecode}>
                            ({phonecode}) {nombre} 
                        </Option>
                    )
                }

                return (
                    <Option value={_id} key={_id}>
                        {nombre ?? ''}
                    </Option>
                )


            })}
        </Select>
    );
};

export default SelectPais;
