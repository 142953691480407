import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";

import '../Styles/Global/admin.css';

// Routers
import RouterRifas from "./RouterRifas";
import RouterRoles from "./RouterRoles";
import RouterVentas from "./RouterVentas";
import RouterUsuarios from "./RouterUsuarios";
import RouterClientes from "./RouterClientes";
import RouterDashboard from "./RouterDashboard";
import RouterCarousel from "./RouterCarousel.js";
import BusquedaBoletos from "../Components/Public/BusquedaBoletos.js";

// Components
import Navbar from "../Components/Header/Header";
import { PrivateRoutes } from "./Middleware/PrivateRoutes";
import AdminSidebar from "../Components/Sidebar/Sidebar.js";


const { Content } = Layout;

class AdminRoutes extends Component {


	constructor(props){
		super(props)
		this.state = {
			search: undefined
		}
	}


	setFilterSearch = (search) => {
		this.setState({search})
	}

	render() {
		return (
			<PrivateRoutes>
				<Layout className="layout" style={{ overflowX: "hidden" }}>
					<Navbar setFilterSearch={this.setFilterSearch}/>
					<Layout>
						<AdminSidebar />
						<Content className="admin-content">
							<Routes>
	                            <Route path="dashboard/*" 	element={<RouterDashboard  search={this.state.search} />} />
	                            <Route path="usuarios/*" 	element={<RouterUsuarios  search={this.state.search} />} />
								<Route path="roles/*" 		element={<RouterRoles search={this.state.search} />} />
								<Route path="rifas/*" 		element={<RouterRifas  search={this.state.search} />} />
								<Route path="clientes/*" 	element={<RouterClientes  search={this.state.search} />} />
								<Route path="ventas/*" 		element={<RouterVentas  search={this.state.search} />} />
								<Route path="carousel/*" 	element={<RouterCarousel  search={this.state.search} />} />
								<Route path="boletos/*" 	element={<BusquedaBoletos header={false} />} />
							</Routes>
						</Content>
					</Layout>
					{/* <ISYWidget
						nombre={(this.props?.user?.nombre) + " " + this.props?.user?.apellidos}
						email={this.props?.user?.email}
						proyectoId="64553057150f1736794bb487"
						URL="https://ws.admin.iseeyoutech.com"
					/> */}
				</Layout>
			</PrivateRoutes>
		)
	}
}

export default (props) => <AdminRoutes {...props} />