import React, { Component, useContext } from 'react'
import { Row, Layout, Col, Form, Input, Button, Typography, Modal, Select, Empty, Spin } from 'antd';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { User, SetUser } from '../../Hooks/Logged';
import axios from 'axios'


import Footer from './Footer'
import Header from './Header'
import SelectSorteo from '../Widgets/Inputs/SelectSorteo'
import SelectBoletos from '../Widgets/Inputs/SelectBoletos'
//css
import '../../Styles/Global/auth.scss';
import './BusquedaBoletos.scss';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Title, Text } = Typography
const { Option } = Select

/**
 * 
 * @class Register
 * @extends {Component}
 * @description Componente para registrarse
 */
class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			boletos: [],
			sorteoId: null,
			sorteo: null,
			loading: false
		}
	}

	/**
	 * @memberof Register
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 **/
	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.get('/landing/venta-boletos', {
			params: values
		})
			.then(({ data, headers }) => {
				this.setState({ boletos: data })

				if (window.innerWidth < 800)
					document.getElementById('tickets').scrollIntoView({
						behavior: 'smooth'
					});
			})
			.catch((error) => {
				console.log("error", error);
				Modal.error({
					title: "Ocurrio un error en el registro",
					content: error?.response?.data?.message ?? "Intente mas tarde"
				})
			})
			.finally(() => {
				this.setState({ loading: false })
			})
	};


	// form = 
	render() {
		// console.log("thi", this.state.boletos)

		const { sorteo } = this.state
		const { header = true } = this.props

		const digits = String(sorteo?.cantidad_boletos).length;
		return (
			<Layout id={'landing'} className="transparent landing ">
				{header && <Header scrollAnimation={false} className="scrolling" />}
				<Spin spinning={this.state.loading}>
					<Row className='layout-login' style={header ? { paddingTop: 70 } : {}}>
						<Col md={10} xs={24}>
							<div className='login-form-container' style={{
								position: 'sticky',
								top: '120px',
								marginBottom: '20px',
								padding: 10
							}}>
								<Form
									ref={(e) => this.ref = e}
									onFinish={this.handleSubmit}
									layout={"vertical"}
									requiredMark={false}
									className='login-form'
								>
									<img src="/fugapadurango-fill.svg" style={{ height: 60, margin: "0 auto 20px auto" }} />

									<Title level={3} className="h3-title mb-1">Busca tus Boletos</Title>
									<Title level={5} className="h3-title mb-1">Selecciona el sorteo e ingresa los boletos que deseas buscar. </Title>
									<Row gutter={[16, 0]}>
										<Col span={24}>
											<Form.Item
												name="sorteo_id"
												label="Seleccione el sorteo"
												rules={[
													{ required: true, message: 'Debe seleccionar un sorteo' },
												]}>
												<SelectSorteo
													onSelect={(sorteoId, option) => {
														this.setState({ sorteoId, sorteo: option.item })
													}}
												/>
											</Form.Item>
											<Form.Item
												name="boletos"
												label="Boletos a Buscar"
												rules={[{ required: true, message: 'Ingrese los boletos' },]}>
												<SelectBoletos
													sorteoId={this.state.sorteoId}
													digits={digits}
													setBoleto={(x)=> {
														const values = this.ref.getFieldsValue()

														if (!Array.isArray(values['boletos']))
															values['boletos'] = []

														if ((values['boletos'].filter((num) => Number(num) == Number(x)).length == 0)) {
															values['boletos'].push(x)
															this.ref.setFieldsValue({
																boletos: values['boletos']
															})
														}
														console.log("values['boletos'].filter(words.filter((num) => Number(num) == Number(x)))", values['boletos'].filter((num) => Number(num) == Number(x)))
														// words.filter((word) => word.length > 6);
														// if ()
															
														// console.log("XXXX", boletos)
													}}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Form.Item>
										<Button type="primary" htmlType="submit" block size="large" >Buscar</Button>
									</Form.Item>
									<Form.Item>
										<Button type="dashed" onClick={async () => {
											// const va
											try {
												await this.ref.validateFields()
												const values = this.ref.getFieldsValue()
												const url = new URL(axios.defaults.baseURL + '/landing/venta-boletos');
												url.searchParams.append('sorteo_id', this.state.sorteoId);
												url.searchParams.append('pdf', this.state.sorteoId);
												values.boletos.forEach(id => {
													url.searchParams.append('boletos[]', id);
												});
												window.open(url, "_blank")
											} catch (error) {
												console.log("E", error)
											}

											console.log('this.ref',)
											// url.searchParams.append('Authorization', sessionStorage.getItem('token'));
										}} block size="large" >Imprimir</Button>
									</Form.Item>
									{/* <Text>¿Ya tienes cuenta? <Link to="/login"></Link></Text> */}
								</Form>
							</div>
						</Col>
						<Col md={14} className='tickets-container' xs={24} id='tickets' s>
							<div style={{
								justifyContent: "center",
								alignItems: "center",
								display: 'flex',
								height: '100%',
								flexDirection: "column",
								// borderTopWidth: 1,
								// borderTopColor: "#f0f2f5"
								// background: !header ? "#f0f2f5 !important" : null
							}}>
								{(Array.isArray(this.state.boletos) && this.state.boletos.length > 0) ? this.state.boletos.map(boleto => {
									const { sorteo, vendido } = boleto
									if (vendido)
										return <div className='ticket'>
											<div className='ticket-header'>
												<img src='/dark-logo.svg' height={32} />
												<div >
													<Paragraph className='ticket-subtitle'>NUMERO DE BOLETO</Paragraph>
													<Paragraph className='ticket-title'>{String(boleto.numero).padStart(digits, '0')}</Paragraph>
												</div>
											</div>
											<div className='ticket-content'>
												<img className='markwater' src='/logo512.png' />
												<Paragraph className='ticket-description'>NOMBRE</Paragraph>
												<Paragraph className='ticket-value'>{boleto.nombre} {boleto.apellido}</Paragraph>
												<Paragraph className='ticket-description'>TELEFONO</Paragraph>
												<Paragraph className='ticket-value'>{boleto.telefono}</Paragraph>
												<Paragraph className='ticket-description'>ESTADO</Paragraph>
												<Paragraph className='ticket-value'>{boleto.estado ?? "N/D"}</Paragraph>
												<Paragraph className='ticket-description'>Pais</Paragraph>
												<Paragraph className='ticket-value'>{boleto.pais ?? "N/D"}</Paragraph>
											</div>
											<div className='ticket-banner'>
												<div className='ticket-banner-ribbon'>
													<Title>{sorteo.nombre}</Title>
												</div>
												<img style={{ width: '100%', maxHeight: '250px', objectFit: 'cover' }} className='ticket-banner-img' src={sorteo.banner ? `${axios.defaults.baseURL}/info/${sorteo._id}/banner` : "/img/recientes-1.png"} />
											</div>
										</div>
									else
										return <div className='ticket'>
											<div className='ticket-header'>
												<img src='/dark-logo.svg' height={32} />
												<div >
													<Paragraph className='ticket-subtitle'>NUMERO DE BOLETO</Paragraph>
													<Paragraph className='ticket-title'>{String(boleto.numero).padStart(digits, '0')}</Paragraph>
												</div>
											</div>
											<div className='ticket-content' style={{ textAlign: "center", padding: '20px 10px' }}>
												<img className='markwater' src='/logo512.png' />
												<Paragraph className='ticket-description' style={{ textAlign: "center", fontSize: 20 }}>¡Este boleto está disponible!</Paragraph>
												<Paragraph className='ticket-value'>
													¡Ve al sorteo y adquiere este boleto!
												</Paragraph>
												<Row style={{ width: "100%", textAlign: "center", justifyContent: "center" }}>
													<a href={"/evento/" + this.state.sorteo?.url}>
														<Button type="primary" htmlType="submit" >Ir al sorteo y adquirir</Button>
													</a>
												</Row>

											</div>
											<div className='ticket-banner'>
												<div className='ticket-banner-ribbon'>
													<Title>{sorteo.nombre}</Title>
												</div>
												<img style={{ width: '100%', maxHeight: '100px', objectFit: 'cover' }} className='ticket-banner-img' src={sorteo.banner ? `${axios.defaults.baseURL}/info/${sorteo._id}/banner` : "/img/recientes-1.png"} />
											</div>
										</div>
									// return "asfasdfasd"
								}) : <div className='ticket'>
									<div className='ticket-header' style={{ textAlign: "center" }}>
										<img src='/dark-logo.svg' height={32} style={{ display: "block", margin: "0 auto" }} />
										{/* <div >
									<Paragraph className='ticket-subtitle'>BUSQUEDA DE BOLETOS</Paragraph>
								</div> */}
									</div>
									<div className='ticket-content' style={{ textAlign: "center", padding: '20px 10px' }}>
										<img className='markwater' src='/logo512.png' />
										<Paragraph className='ticket-description' style={{ textAlign: "center", fontSize: 20 }}>SELECCIONA SORTEO Y BOLETO</Paragraph>
										<Paragraph className='ticket-value'>Para realizar una busqueda de boletos, selecciona un sorteo y los boletos a buscar</Paragraph>
									</div>

								</div>}
							</div>
						</Col>


					</Row>
				</Spin>
				{header && 				<Footer />}


			</Layout>
		)
	}
}

export default function View(props) {

	let user = useContext(User)
	const setUser = useContext(SetUser)

	return <Register {...props} user={user} setUser={setUser} navigate={useNavigate()} />
}