import React, { Component, useContext } from "react";
import { Row, Tabs, Col, Card, message, Popconfirm, Menu, Input, Typography, Divider, Checkbox } from "antd";
import Decimal from "decimal.js";
import axios from "axios";
import { Button } from "antd/lib/radio";
import { Carrito, SetCarrito } from "../../Hooks/Carrito";
import TagBoleto from "./TagBoleto";
import { useCookies } from "react-cookie";
import { Content } from "antd/lib/layout/layout";
import ModalGuest from './ModalComprar'
// import Guest from "../../Hooks/Guest";
import User from "../../Hooks/Logged";
import ModalRuleta from "./ModalRuleta";
import "./Boletos.scss";
import { DownOutlined, LeftOutlined, RightOutlined, StopOutlined } from "@ant-design/icons";
import Compra, { SetCompra } from "../../Hooks/SorteoCompra";
import { OpenModalCompra, SetOpenModalCompra } from "../../Hooks/OpenModalCompra";
// import Paragraph from "antd/lib/skeleton/Paragraph";


const { Title,Paragraph } = Typography

class Boletos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            boletosVendidos: [],
            boletosSeleccionados: {},
            total: 0,
            sorteo_id: this.props.sorteo_id,
            millares: 1,
            centenas: 1,
            costo: 100,

            optionMillares: null,
            busqueda: null,
            secctionVentaVisible: true,

        };
    }

    boletosRef = React.createRef()


    componentDidMount() {
        this.setState({ total: this.props.total })
        this.setState({ costo: this.props.costo })
        this.setState({ sorteo_id: this.props.sorteo_id })
        if (this.props.cart) {
            // console.log("cart", this.props.cart)

            let boletosSeleccionados = {}
            this.props.cart.forEach(item => {
                boletosSeleccionados[item.numero] = true
            })
            this.setState({ boletosSeleccionados: boletosSeleccionados })
        }
        this.getDisponibles();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.total != this.props.total || prevProps.costo != this.props.costo || prevProps.sorteo_id != this.props.sorteo_id) {
            this.setState({ total: this.props.total, costo: this.props.costo, sorteo_id: this.props.sorteo_id })
            this.getDisponibles()
            this.setState({ optionMillares: 0, optionCentenas: 0 })
        }
        if (prevProps.cart !== this.props.cart) {
            if (this.props.cart) {
                console.log("cart", this.props.cart)
                let boletosSeleccionados = {}
                this.props.cart.forEach(item => {
                    boletosSeleccionados[item.numero] = true
                })
                this.setState({ boletosSeleccionados: boletosSeleccionados })
            }

            this.getDisponibles()
        }
    }


    getDisponibles = (
        min = 0,
        max = 100,
        busqueda = this.state.busqueda
    ) => {
        if (this.props.sorteo_id == null || this.props.sorteo_id == 'undefined') return null
        console.log("Get disponibles", min, max)
        console.log("XXXXX", busqueda)
        axios.get('/info/' + this.props.sorteo_id + '/boletos', {
            params: {
                min: min ?? 1,
                max: max ?? 100,
                busqueda
            }
        })
            .then(({ data }) => {
                this.setState({
                    boletosVendidos: [...data],

                })
            })
            .catch(error => {
                message.error(error?.response?.data?.message ?? "Error al obtener la información")
            }).finally(() => this.setState({ loading: false }))
    }

    addToCart = (numero, costo, sorteo_id) => {
        const cart = this.props.cart ?? [];
        const index = cart.findIndex(item => item.numero == numero);


        if (index == -1) {
            cart.push({
                numero: numero,
                costo: costo,
                sorteo_id: sorteo_id,
                sorteo: this.props.sorteo
            })
            this.setState({ boletosSeleccionados: { ...this.state.boletosSeleccionados, [numero]: true } })
            message.info(`Boleto no. ${numero} agregado al carrito`)
        } else {
            cart.splice(index, 1)
            let nuevosBoletos = this.state.boletosSeleccionados
            delete nuevosBoletos[numero]
            this.setState({ boletosSeleccionados: nuevosBoletos })
            message.info("Boleto eliminado del carrito")
        }

        this.setState({ loading: true })

        if (cart.length < 1) {
            this.props.setCompra(null)
        } else {
            this.props.setCompra(this.props.sorteo)
        }
        this.props.setCart(cart)
    }

    renderBoletos = ({ digits }) => {
        const { boletosVendidos, busqueda, total, optionMillares, optionCentenas } = this.state;

        const generarItemsMenu = (count, labelFn) => new Array(count).fill(null).map((_, index) => ({
            label: labelFn(index),
            key: String(index),
        }));

        const generarLabelMillares = (i) => {
            const inicio = i * 1000;
            const fin = Math.min(total, (i + 1) * 1000);
            return `${inicio} - ${fin}`;
        };

        const generarLabelCentenas = (index) => {
            const inicio = optionMillares * 1000 + index * 100;
            const fin = Math.min(total, inicio + 100);
            return `${inicio} - ${fin}`;
        };

        const handleBusquedaChange = (event) => {
            this.setState({ busqueda: event.target.value }, () => this.getDisponibles());
        };

        const handleMillaresSelect = ({ key }) => {
            const min = Number(key) * 1000;
            this.setState({ optionMillares: Number(key), optionCentenas: 0 }, () =>
                this.getDisponibles(min, min + 100)
            );
        };

        const handleCentenasSelect = ({ key }) => {
            const min = optionMillares * 1000 + Number(key) * 100;
            const max = min + 100;
            this.setState({ optionCentenas: Number(key) }, () => this.getDisponibles(min, max));
        };

        const renderBotonBoleto = (boleto) => {
            const { numero, vendido } = boleto;
            const seleccionado = this.state.boletosSeleccionados[numero];
            return (
                <Col xs={8} sm={4} md={4} lg={2} xl={2} xxl={2} key={numero} className="center">
                    <Button
                        className={`button-boleto ${vendido ? 'vendido' : seleccionado ? 'seleccionado' : ''}`}
                        onClick={() => this.addToCart(numero, this.state.costo, this.state.sorteo_id)}
                        disabled={vendido}
                    >
                        {String(numero).padStart(digits, '0')}
                        {/* {vendido && <StopOutlined className="denied" />} */}
                    </Button>
                </Col>
            );
        };

        const renderMenus = () => (
            <>
                <Menu
                    className="menu-boletos"
                    align="center"
                    selectedKeys={[String(optionMillares)]}
                    mode="horizontal"
                    items={generarItemsMenu(
                        Math.ceil(total / 1000),
                        generarLabelMillares
                    )}
                    onSelect={handleMillaresSelect}
                />
                <Menu
                    className="menu-boletos"
                    align="center"
                    selectedKeys={[String(optionCentenas)]}
                    mode="horizontal"
                    items={generarItemsMenu(
                        optionMillares * 1000 + 1000 <= total ? 10 : Math.ceil(total % 1000 / 100),
                        generarLabelCentenas
                    )}
                    onSelect={handleCentenasSelect}
                />
            </>
        );

        return (
            <>
                <Row style={{ width: '100%', justifyContent: 'center', gap: 20,alignItems: "center" }}>
                    <Input
                        onChange={handleBusquedaChange}
                        placeholder="Buscar un boleto..."
                        style={{ maxWidth: 300 }}
                    />
                    {/* <Checkbox >
                        Solo mostrar boletos disponbles
                    </Checkbox> */}
                </Row>
                {!busqueda && renderMenus()}
                <Row className="mt-1" align="middle" justify="center" gutter={[16, 16]}>
                    {boletosVendidos.map(renderBotonBoleto)}
                </Row>
                {/* <ModalGuest
                    visible={this.state.modalGuestVisible}
                    onSuccess={() => {
                        message.success('Gracias, puede continuar con la compra');
                        this.setState({ modalGuestVisible: false });
                    }}
                    onClose={() => {
                        this.setState({ modalGuestVisible: false });
                    }}
                /> */}
            </>
        );
    };

    refHeight = React.createRef()




    render() {
        let digits = String(this.state.total).length



        return <Content id="boletos">

            {(this.props.compra?._id && this.props.compra?._id != this.props.sorteo_id) ? <div className="not-available">

                <Card   className="card-not">
                    <Title level={4}>Tienes una compra pendiente</Title>
                    <Paragraph>
                        Para poder comprar en este sorteo ¡Concluye tu compra pendiente!
                    </Paragraph>

                    <Button  onClick={() => this.props.setOpenModalCompra(true)} className="continuar-compra" type="primary">Concluir mi compra</Button>
                </Card>
            </div> : null}
            {this.renderBoletos({ digits })}
            {
                (Array.isArray(this.props?.cart) && this.props?.cart.length) ?
                    <div
                        ref={this.refHeight}
                        className="selected-tickets"
                        style={{
                            bottom: 0,
                            transform: this.state.secctionVentaVisible ? null : 'translateY(calc( 100% - 50px ))'
                        }}
                    >
                        <div className="selected-tickets-wrapper">
                            <Row className="selected-tickets-header" onClick={() => {
                                this.setState({
                                    secctionVentaVisible: !this.state.secctionVentaVisible,
                                })
                            }}>
                                <Title level={4} className='section-title' style={{ marginBottom: 0 }}>
                                    Has seleccionado
                                    <span className='orange'> {this.props?.cart.length} boleto{(this.props.cart.length > 1) ? "s" : ""}</span>
                                </Title>
                                <DownOutlined style={{ position: "relative", top: 8, }} />
                            </Row>
                            <Divider />
                            <Row className="selected-tickets-tickets" align="middle" justify="center" gutter={[16, 16]}>
                                {this.props?.cart?.map((obj) => {
                                    const { numero, vendido = false, seleccionado = true, } = obj
                                    return <Col xs={8} sm={4} md={4} lg={2} xl={2} xxl={2} key={numero} className="center">
                                        <Button
                                            className={`button-boleto ${vendido ? 'vendido' : seleccionado ? 'seleccionado' : ''}`}
                                            onClick={() => this.addToCart(numero, this.state.costo, this.state.sorteo_id)}
                                            disabled={vendido}
                                        >
                                            {String(numero).padStart(digits, '0')}
                                        </Button>
                                    </Col>
                                })}
                            </Row>
                            <Button onClick={() => this.props.setOpenModalCompra(true)} className="continuar-compra">Continuar con mi Compra</Button>
                        </div>
                    </div> : null
            }
        </Content>

    }
}

export default function Vista(props) {

    let cart = useContext(Carrito);
    let setCart = useContext(SetCarrito)

    let compra = useContext(Compra);
    let setCompra = useContext(SetCompra)

    // OpenModalCompra, SetOpenModalCompra
    // let openModalCompra = ;
    // let setOpenModalCompra = 

    let user = useContext(User)
    const [cookies, setCookie] = useCookies(['cart']);
    // console.log('cookies')
    return <Boletos
        {...props}

        cart={cart}
        setCart={setCart}

        compra={compra}
        setCompra={setCompra}
        
        openModalCompra={useContext( OpenModalCompra)}
        setOpenModalCompra={useContext(SetOpenModalCompra)}

        user={user}
        cookies={cookies}
        setCookie={setCookie}
    />

}